<template>
  <div>
    <div class="mb-5">
      <v-autocomplete
        class="c-input-small"
        v-model="id_reason"
        :items="reasonOptions"
        :placeholder="$t('labels.reason')"
        :label="$t('labels.reason')"
        :readonly="isLoading"
        ref="reasonRef"
        @change="onReasonChanged"
        dense
        outlined
        hide-details
      ></v-autocomplete>
    </div>

    <div class="mb-5" v-if="isRequireNote">
      <v-textarea
        class="c-input-small"
        v-model="note"
        :placeholder="$t('labels.note')"
        :label="$t('labels.note')"
        :single-line="false"
        :rows="2"
        :readonly="isLoading"
        ref="noteRef"
        dense
        outlined
        clearable
        hide-details
      ></v-textarea>
    </div>

    <div class="error--text text-center" v-if="isWarningSeparation">
      {{ $t("messages.processing_wait") }}
    </div>
    <div v-else>
      <div class="mb-5">
        <input-qr-scan-model
          v-model="code"
          ref="codeRef"
          :disabled="isDisabledCodeInput"
          :label="$t('labels.uid_sku_barcode')"
          @keyupEnter="scanInput"
        ></input-qr-scan-model>
      </div>

      <div class="error--text text-center" v-if="isLoading">
        {{ $t("messages.processing_wait") }}
      </div>
    </div>

    <v-dialog v-model="dialogGoodsLocation" persistent max-width="480px">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("labels.position") }}
          <v-spacer></v-spacer>
          <v-btn
            small
            color="red darken-1"
            text
            @click="hideDialogGoodsLocation"
            :disabled="countDown > 0"
          >
            <span v-if="countDown > 0">({{ countDown }}s)</span>
            <span v-else>{{ $t("labels.close") }}</span>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table
            fixed-header
            height="calc(100vh - 500px)"
            class="table-padding-2"
          >
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.sku") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.barcode") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.position") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.quantity") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-for="(item, index) in goodsLocations"
                  :key="`gl_${index}`"
                >
                  <td>{{ item.sku }}</td>
                  <td>{{ item.customer_goods_barcode }}</td>
                  <td>{{ item.cell_id }}</td>
                  <td>{{ item.quantity }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  downloadExcelFile,
  downloadPdfServerFilename,
  formatDateTime,
  isMobile,
  hideString,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { REASON_TYPE_EMPLOYEE_EXPORT_ITEM } from "@/libs/const";
import moment from "moment";

export default {
  name: "EmployeeExport",
  components: {},
  data: () => ({
    isLoading: false,
    dialogGoodsLocation: false,
    goodsLocations: [],
    code: null,
    note: null,
    id_reason: null,
    reasons: [],
    items: [],
    countDown: 0,
  }),
  computed: {
    reasonOptions() {
      return [...this.reasons].map((r) => ({
        text: r[`${this.$i18n.locale}_description`] || r["description"],
        value: r.id,
      }));
    },
    isDisabledCodeInput() {
      return !this.id_reason || (this.isRequireNote && !this.note);
    },
    isRequireNote() {
      if (!this.id_reason) {
        return false;
      }

      const selected = [...this.reasons].find((r) => r.id == this.id_reason);
      return !!selected.is_require_note;
    },
    isWarningSeparation() {
      if (!this.id_reason) {
        return false;
      }
      const selected = [...this.reasons].find((r) => r.id == this.id_reason);
      return !!selected.is_item_separation && this.isMobile();
    },
  },
  mounted() {
    this.getReasons();
  },
  methods: {
    downloadExcelFile,
    downloadPdfServerFilename,
    formatDateTime,
    isMobile,
    hideString,
    countdownForClose() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countdownForClose();
        }, 1000);
      }
    },
    onReasonChanged() {
      this.$refs.reasonRef.blur();
      setTimeout(() => {
        if (!this.isRequireNote) {
          this.$refs.codeRef.focusInput();
        } else {
          this.$refs.noteRef.focus();
        }
      }, 500);
    },
    getReasons() {
      httpClient
        .post("/common/v1/get-reason", {
          type: REASON_TYPE_EMPLOYEE_EXPORT_ITEM,
        })
        .then(({ data }) => {
          this.reasons = [...data];
        });
    },
    async scanInput() {
      if (!this.code) {
        return false;
      }

      this.isLoading = true;
      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/employee-export-order",
          {
            code: this.code,
            id_reason: this.id_reason,
            note: this.note,
          }
        );

        this.code = null;

        // this.$vToastify.success(this.$t("messages.create_success"));
        // document.getElementById("success_sound_player").play();
        setTimeout(() => {
          this.processOrder(data);
        }, 2000);
        return true;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    async processOrder(input) {
      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/employee-export-order-change-status",
          input
        );
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        if (data && data.is_item_separation) {
          this.goodsLocations = [...data.itemLocations];
          for (let i = 0; i < data.itemLocations.length; i++) {
            const itemLocation = data.itemLocations[i];
            if (itemLocation.uidIds && itemLocation.uidIds.length > 0) {
              this.downloadStamp(itemLocation);
            }
          }
          // them in tem
          this.showDialogGoodsLocation();
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;

        if (errMsg === "WaitProcess") {
          return this.processOrder(input);
        }

        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    async downloadStamp(item) {
      await this.downloadPdfServerFilename("/common/v1/generate-goods-stamp", {
        id_goods: item.id_goods,
        id_goods_receipt_history: item.id_goods_receipt_history,
        client_time: moment().format("HH:mm DD/MM/YYYY"),
      });
    },
    showDialogGoodsLocation() {
      this.dialogGoodsLocation = true;
      this.countDown = 10;
      this.countdownForClose();
    },
    hideDialogGoodsLocation() {
      if (this.countDown > 0) {
        return false;
      }
      this.goodsLocations = [];
      this.dialogGoodsLocation = false;
    },
  },
};
</script>

<style scoped></style>
